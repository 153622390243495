// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Vue Select
@import "~vue-select/src/scss/vue-select.scss";


.little-top-box {
    width: 100%;
    max-width: 500px;
}

.border-box {
    box-sizing: border-box;
}

.table thead th {
    vertical-align: bottom;
    border: none !important;
    color: #A3A6B4 !important;
}
.table thead {
    border: none !important;
    background-color: #F5F6FA !important;
}

.table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-bottom: 1px solid #F1F1F3;
}

.table tbody th {
    width: 80px !important;
    text-align: center !important;
}

a.btn-primary {
    color: white !important;
    text-decoration: none !important;
}

ul {
    margin-bottom: 0 !important;
}

.statbox {
    background-color: white;
    display: inline-block;
    padding: 20px;
    width: 350px;
    height: 125px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.20);
    border-radius: 7px;

    background-repeat: no-repeat;
    background-size: 60px 60px;
    background-position: bottom right;
}

.statbox-title {
    font-size: 14px;
    color: #787878;
}

.statbox-text {
    font-size: 35px;
    font-weight: bold;
}

.statbox-subtitle {
    color: #C4C4C4;
    font-size: 12px;
}
.text-primary {
    color: #67C9E0 !important;
}
.btn-primary {
    background-color: #67C9E0 !important;
    border-color: #67C9E0 !important;
}

.btn-primary:hover {
    background-color: #46c1de !important;
    border-color: #46c1de !important;
}

.btn-primary:active {
    background-color: #46c1de !important;
    border-color: #46c1de !important;
}

.btn-primary.focus {
    background-color: #46c1de !important;
    border-color: #46c1de !important;
}

.btn-link {
    font-weight: 400 !important;
    color: #3490dc !important;
    text-decoration: none !important;
}
.btn-link:hover {
    color: #1d68a7 !important;
    text-decoration: underline !important;
}


.vdp-datepicker input {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.vdp-datepicker input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}


// Progress Box CSS ---START---
.progress-box {
    position: relative;
    display: inline-flex;
    padding: 10px 20px;
    border: 1px solid #E4E4E5;
    border-left: none;
    background-color: white;
    min-width: 100px;
    cursor: pointer;
    height: 75px;
    align-items: center;

}

.progress-box.prog_arrow:after, .progress-box.prog_arrow:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    z-index: 2;
    pointer-events: none;
}

.progress-box.prog_arrow:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: white;
    border-width: 15px;
    margin-top: -15px;
}
.progress-box.prog_arrow:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #E4E4E5;
    border-width: 17px;
    margin-top: -17px;
}

.progress-box-icon {
    font-size: 30px;
    line-height: 30px;
    width: 100%;
    padding: 5px;
    text-align: center;
    display: none;
}
.progress-box-title {
    font-size: 14.5px;
    font-weight: bold;
    line-height: 19px;
    width: 100%;
    margin-left: 5px;
    text-align: left;
}
.progress-box-step {
    font-size: 12.5px;
    line-height: 15px;
    margin-left: 5px;
    text-align: left;
}
.progress-box-container {
    display: flex;
    user-select: none;
}
.prog-end-l {
    border: 1px solid #E4E4E5;
    border-radius: 4px 0 0 4px;
}
.prog-end-r {
    border-radius: 0 4px 4px 0;
}
.prog_done {
    background-color: #65DE71;
    color: white !important;
}
.prog_active {
    background-color: #4CC3D2;
    color: white !important;
}
.prog_done .text-primary, .prog_active .text-primary {
    color: white !important;
}
.prog_done.prog_arrow:after {
    border-left-color: #65DE71 !important;
}
.prog_active.prog_arrow:after {
    border-left-color: #4CC3D2 !important;
}
.progress-box-container > a {
    color: black;
}
.cil-lock-locked {
    font-size: 30px;
}
// Progress Box CSS ---END---

// Address Finder CSS ---START---
.address_search_popup_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.address_search_popup_close {
    background-color: rgb(0 0 0 / 60%);
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.address_search_popup {
    background-color: white;
    position: absolute;
    width: 800px;
    height: 610px;
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}
.address_search_popup_title_container {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    cursor: default;
}
.address_search_popup_title {
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    font-size: calc(1.275rem + .3vw);
}
.address_search_popup_body {
    padding: 20px;
    max-width: 700px;
    margin: auto;
}
.address_search_result_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.address_search_result_row {
    padding: 10px 20px;
    border: 1px solid #F1F1F3;
    border-top: none;
    background-color: #F9F9F9;
}
.address_search_result_row:nth-child(even){
    background-color: white;
}

.address_search_input {
    background-color: white !important;
    cursor: text;
}
.address_search_input_active {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #a1cbef !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(52 144 220 / 25%) !important;
}
// Address Finder CSS ---END---

// Customising the BootStrap card
.card-header {
    background: white;
    font-weight: bold;
    font-size: 18px;
}

.card {
    border-radius: 0;
}

.bg-primary  {
    background-color: #67c9e0 !important;
}

.hl {
    display: flex;
    margin: 1rem 0;
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: content-box;
}

/* EVT styling ---START--- */
.evt_container {
    box-shadow: #0000001f 0 1px 4px 1px;
}
.evt_sidepanel {
    overflow: hidden;
    font-size: 15px;
}
.evt_sidepanel_data {
    color: #CAEBF3;
}
.evt_contents {
    background-color: white;
}
.evt_title {
    width: 100%;
    line-height: 1.6;
    color: #212529;
    font-weight: bold;
    font-size: 18px;
    padding: 15px 0;
    border-bottom: 1px solid #E8E8E8;
}
/* EVT styling ---END--- */

